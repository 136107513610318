import React from 'react';
import { Select, SelectProps } from 'antd';
import { ContractType } from '@/api/Contracts/types';

const types: { value: ContractType; title: string }[] = [
  {
    value: 'electrical_grid',
    title: 'Электросети',
  },
  {
    value: 'heating_network',
    title: 'Теплосети',
  },
  {
    value: 'gas_pipeline',
    title: 'Газопроводы',
  },
];

type Props = SelectProps<string>;
export const SelectContractType: React.FC<Props> = React.memo((props) => {
  return (
    <Select placeholder={'Выберите тип контракта'} style={{ width: '100%' }} {...props}>
      {types.map((c) => (
        <Select.Option key={c.value} value={c.value}>
          {c.title}
        </Select.Option>
      ))}
    </Select>
  );
});
