import { contractsQueryKeys } from '@/hooks/contracts/queryKeys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Api } from '@/api';
import {
  CadObjectGenFileRequest,
  CadObjectGenMifRequest,
  CadObjectGenRastersRequest,
  CadObjectGenXmlRequest,
  CadObjectResponse,
  CadObjectsAutocompleteRequest,
  GetCadObjectsPageRequest,
} from '@/api/CadObjects/types';
import { CoupleFileResponse, FileRecordResponse } from '@/api/Files/types';
import { TdRecordResponse } from '@/api/Td/types';
import { RecordId } from '@/api/common/types';
import { cadObjQueryKeys } from '@/hooks/cadastralObjects/queryKeys';
import { statisticsQueryKeys } from '@/hooks/statistics/queryKeys';
import { createUseFetchingStateObserver } from '@/hooks/utils';

export const useGetCadObjectsPageQ = (data: GetCadObjectsPageRequest) => {
  return useQuery(cadObjQueryKeys.page(data), () => Api.cadastralObjects.get(data), {
    select: (resp) => resp.data,
    keepPreviousData: true,
  });
};

export const useCadObjectsPageFetchingState = createUseFetchingStateObserver(
  cadObjQueryKeys.pages(),
);

export const useGetCadObjectByIdQ = (id: RecordId) => {
  return useQuery(cadObjQueryKeys.detail(id), () => Api.cadastralObjects.getById(id), {
    select: (data) => data.data.data,
  });
};

export const useCadObjectFilesQ = (data: { id: RecordId; dataIndex: keyof CadObjectResponse }) => {
  return useQuery(cadObjQueryKeys.detailFiles(data.id, data.dataIndex), async () => {
    const getByIdRes = await Api.cadastralObjects.getById(data.id);
    const cadObject = getByIdRes.data.data;
    return cadObject[data.dataIndex] as FileRecordResponse[];
  });
};

export const useCadObjectTdsQ = (data: { id: string; dataIndex: keyof CadObjectResponse }) => {
  return useQuery(cadObjQueryKeys.detailFiles(data.id, data.dataIndex), async () => {
    const getByIdRes = await Api.cadastralObjects.getById(data.id);
    const cadObject = getByIdRes.data.data;
    return cadObject[data.dataIndex] as TdRecordResponse[];
  });
};

export const useCadObjectFileQ = (data: { id: string; dataIndex: keyof CadObjectResponse }) => {
  return useQuery(cadObjQueryKeys.detailFiles(data.id, data.dataIndex), async () => {
    const getByIdRes = await Api.cadastralObjects.getById(data.id);
    const cadObject = getByIdRes.data.data;
    return cadObject[data.dataIndex] as FileRecordResponse | undefined;
  });
};

export const useCadObjectFileCouplesQ = (data: {
  id: string;
  dataIndex: keyof CadObjectResponse;
}) => {
  return useQuery(cadObjQueryKeys.detailFiles(data.id, data.dataIndex), async () => {
    const getByIdRes = await Api.cadastralObjects.getById(data.id);
    const cadObject = getByIdRes.data.data;
    return cadObject[data.dataIndex] as unknown as CoupleFileResponse[];
  });
};

//Mutations

export const useCreateCadObjectM = () => {
  const queryClient = useQueryClient();

  return useMutation(cadObjQueryKeys.create(), Api.cadastralObjects.create, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(cadObjQueryKeys.pages()),
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistic(variables.contractId)),
        queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
      ]);
    },
  });
};

export const usePatchCadObjectM = () => {
  const queryClient = useQueryClient();

  return useMutation(cadObjQueryKeys.patch(), Api.cadastralObjects.patch, {
    onSuccess: async (data, variables) => {
      const contractId = data.data.data.contractId;

      await Promise.all([
        queryClient.invalidateQueries(cadObjQueryKeys.pages()),
        queryClient.invalidateQueries(cadObjQueryKeys.detail(variables.id)),
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistic(contractId)),
        queryClient.invalidateQueries(contractsQueryKeys.notify(contractId)),
      ]);
    },
  });
};

export const useDeleteCadObjectM = () => {
  const queryClient = useQueryClient();

  return useMutation(cadObjQueryKeys.delete(), Api.cadastralObjects.delete, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(cadObjQueryKeys.pages()),
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistics()),
        queryClient.invalidateQueries(contractsQueryKeys.allNotify()),
      ]);
    },
  });
};

export const useDeleteFileInCadObjectCollectionM = () => {
  const patchCadObjectM = usePatchCadObjectM();
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.deleteFileInCollection(),
    async (data: { id: string; dataIndex: keyof CadObjectResponse; deleteFileId: string }) => {
      const getByIdRes = await Api.cadastralObjects.getById(data.id);
      const cadObject = getByIdRes.data.data;
      const files = cadObject[data.dataIndex] as FileRecordResponse[];
      const oldIds = files.map((x) => x._id);
      const newIds = oldIds.filter((x) => x !== data.deleteFileId);

      await patchCadObjectM.mutateAsync({ id: data.id, patch: { [data.dataIndex]: newIds } });
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(
            cadObjQueryKeys.detailFiles(variables.id, variables.dataIndex),
          ),
          queryClient.invalidateQueries(statisticsQueryKeys.contractStatistics()),
          queryClient.invalidateQueries(contractsQueryKeys.allNotify()),
        ]);
      },
    },
  );
};

export const useDeleteFileCoupleInCadObjectCollectionM = () => {
  const { mutateAsync: patchCadObjectM } = usePatchCadObjectM();
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.deleteFileInCollection(),
    async (data: {
      id: string;
      dataIndex: keyof CadObjectResponse;
      deletableCoupleFileIds: [RecordId, RecordId];
    }) => {
      const { deletableCoupleFileIds } = data;

      const getByIdRes = await Api.cadastralObjects.getById(data.id);
      const cadObject = getByIdRes.data.data;
      const coupleFiles = cadObject[data.dataIndex] as CoupleFileResponse[];

      const coupleFileIds = coupleFiles.reduce((acc, x) => {
        if (x[0]) acc.push(x[0]._id);
        if (x[1]) acc.push(x[1]._id);

        return acc;
      }, [] as string[]);

      const newCoupleFileIds = coupleFileIds.filter(
        (x) => x !== deletableCoupleFileIds[0] && x !== deletableCoupleFileIds[1],
      );

      await patchCadObjectM({ id: data.id, patch: { [data.dataIndex]: newCoupleFileIds } });
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(
            cadObjQueryKeys.detailFiles(variables.id, variables.dataIndex),
          ),
          queryClient.invalidateQueries(statisticsQueryKeys.contractStatistics()),
          queryClient.invalidateQueries(contractsQueryKeys.allNotify()),
        ]);
      },
    },
  );
};

export const useDeleteFileInCadObjectM = () => {
  const patchCadObjectM = usePatchCadObjectM();
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.deleteFile(),
    async (data: { id: string; dataIndex: keyof CadObjectResponse }) => {
      await patchCadObjectM.mutateAsync({ id: data.id, patch: { [data.dataIndex]: null } });
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(
            cadObjQueryKeys.detailFiles(variables.id, variables.dataIndex),
          ),
          queryClient.invalidateQueries(statisticsQueryKeys.contractStatistics()),
          queryClient.invalidateQueries(contractsQueryKeys.allNotify()),
        ]);
      },
    },
  );
};

export const useDeleteTdInCadObjectCollectionM = () => {
  const patchCadObjectM = usePatchCadObjectM();
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.deleteFile(),
    async (data: { id: string; dataIndex: keyof CadObjectResponse; deletePdId: string }) => {
      const getByIdRes = await Api.cadastralObjects.getById(data.id);
      const cadObject = getByIdRes.data.data;
      const files = cadObject[data.dataIndex] as FileRecordResponse[];
      const oldIds = files.map((x) => x._id);
      const newIds = oldIds.filter((x) => x !== data.deletePdId);
      await patchCadObjectM.mutateAsync({ id: data.id, patch: { [data.dataIndex]: newIds } });
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(
            cadObjQueryKeys.detailFiles(variables.id, variables.dataIndex),
          ),
          queryClient.invalidateQueries(statisticsQueryKeys.contractStatistics()),
          queryClient.invalidateQueries(contractsQueryKeys.allNotify()),
        ]);
      },
    },
  );
};

export const useCadObjectsImportFromExcelM = () => {
  const queryClient = useQueryClient();
  return useMutation(cadObjQueryKeys.importFromExcel(), Api.cadastralObjects.importFormExcel, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistic(variables.contractId)),
        queryClient.invalidateQueries(cadObjQueryKeys.all),
        queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
      ]);
    },
  });
};

export const useCadObjectsImportOsM = () => {
  const queryClient = useQueryClient();
  return useMutation(cadObjQueryKeys.importOs(), Api.cadastralObjects.importOs, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(cadObjQueryKeys.all),
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistic(variables.contractId)),
        queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
      ]);
    },
  });
};

export const useCadObjectsImportRastrM = () => {
  const queryClient = useQueryClient();
  return useMutation(cadObjQueryKeys.importRastr(), Api.cadastralObjects.importRastr, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(cadObjQueryKeys.all),
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistic(variables.contractId)),
        queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
      ]);
    },
  });
};

export const useCadObjectsGenFilesM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.genFiles(),
    (data: CadObjectGenFileRequest) => {
      return Api.cadastralObjects.genFile(data);
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(cadObjQueryKeys.all),
          queryClient.invalidateQueries(
            statisticsQueryKeys.contractStatistic(variables.contractId),
          ),
          queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
        ]);
      },
    },
  );
};

export const useCadObjectsGenKptM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.genFiles(),
    (data: CadObjectGenXmlRequest) => {
      const { contractId, cadObjectNumber } = data;
      return Api.cadastralObjects.genFile({ cadObjectNumber, contractId, fileTypes: ['kpt'] });
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(cadObjQueryKeys.all),
          queryClient.invalidateQueries(
            statisticsQueryKeys.contractStatistic(variables.contractId),
          ),
          queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
        ]);
      },
    },
  );
};

export const useCadObjectsGenMifM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.genFiles(),
    (data: CadObjectGenMifRequest) => {
      const { cadObjectNumber, contractId } = data;
      return Api.cadastralObjects.genFile({ cadObjectNumber, contractId, fileTypes: ['mif'] });
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(cadObjQueryKeys.all),
          queryClient.invalidateQueries(
            statisticsQueryKeys.contractStatistic(variables.contractId),
          ),
          queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
        ]);
      },
    },
  );
};

export const useCadObjectsGenRastersM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.genFiles(),
    (data: CadObjectGenRastersRequest) => {
      const { cadObjectNumber, contractId } = data;
      return Api.cadastralObjects.genFile({ cadObjectNumber, contractId, fileTypes: ['rasters'] });
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(cadObjQueryKeys.all),
          queryClient.invalidateQueries(
            statisticsQueryKeys.contractStatistic(variables.contractId),
          ),
          queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
        ]);
      },
    },
  );
};

export const useCadObjectsAutocompleteM = () => {
  const queryClient = useQueryClient();
  return useMutation(
    cadObjQueryKeys.autocomplete(),
    (data: CadObjectsAutocompleteRequest) => {
      return Api.cadastralObjects.autocomplete(data);
    },
    {
      onSuccess: async (_, variables) => {
        await Promise.all([
          queryClient.invalidateQueries(cadObjQueryKeys.all),
          queryClient.invalidateQueries(
            statisticsQueryKeys.contractStatistic(variables.contractId),
          ),
          queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
        ]);
      },
    },
  );
};

export const useCadObjectAutoRunM = () => {
  const queryClient = useQueryClient();

  return useMutation(cadObjQueryKeys.autoRun(), Api.cadastralObjects.autoRun, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(cadObjQueryKeys.all),
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistic(variables.contractId)),
        queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
      ]);
    },
  });
};

export const useCadObjectAutoSignM = () => {
  const queryClient = useQueryClient();
  return useMutation(cadObjQueryKeys.autoSign(), Api.cadastralObjects.autoSign, {
    onSuccess: async (data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(cadObjQueryKeys.all),
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistic(variables.contractId)),
        queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
      ]);
    },
  });
};

export const useCadObjectArchivesM = () => {
  const queryClient = useQueryClient();
  return useMutation(cadObjQueryKeys.archives(), Api.cadastralObjects.archives, {
    onSuccess: async (data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries(cadObjQueryKeys.all),
        queryClient.invalidateQueries(statisticsQueryKeys.contractStatistic(variables.contractId)),
        queryClient.invalidateQueries(contractsQueryKeys.notify(variables.contractId)),
      ]);
    },
  });
};
