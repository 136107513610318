import {
  CadObjectArchivesRequest,
  CadObjectArchivesResponse,
  CadObjectAutoRunRequest,
  CadObjectAutoSignRequest,
  CadObjectCreateRequest,
  CadObjectCreateResponse,
  CadObjectGenFileRequest,
  CadObjectGetByIdResponse,
  CadObjectImportFromExcelRequest,
  CadObjectImportOsRequest,
  CadObjectPatchRequest,
  CadObjectPatchResponse,
  CadObjectsAutocompleteRequest,
  GetCadObjectsPageRequest,
  GetCadObjectsPageResponse,
} from '@/api/CadObjects/types';
import { RecordId } from '@/api/common/types';
import { api } from '../axiosInstance';

export class CadastralObjectApi {
  get(params: GetCadObjectsPageRequest) {
    return api.get<GetCadObjectsPageResponse>('/cad_objects', { params });
  }

  getById(id: string) {
    return api.get<CadObjectGetByIdResponse>(`/cad_objects/${id}`);
  }

  create(data: CadObjectCreateRequest) {
    return api.post<CadObjectCreateResponse>('/cad_objects', data);
  }

  patch(data: CadObjectPatchRequest) {
    return api.patch<CadObjectPatchResponse>(`/cad_objects/${data.id}`, data.patch);
  }

  delete(id: RecordId) {
    return api.delete<void>(`/cad_objects/${id}`);
  }

  importFormExcel(data: CadObjectImportFromExcelRequest) {
    const formData = new FormData();
    formData.append('contractId', data.contractId);
    formData.append('file', data.file);
    return api.post<void>('/cad_objects/import_from_excel', formData);
  }

  importOs(data: CadObjectImportOsRequest) {
    const formData = new FormData();
    formData.append('contractId', data.contractId);
    formData.append('file', data.file);
    return api.post<void>('/cad_objects/import_os', formData);
  }

  importRastr(data: CadObjectImportOsRequest) {
    const formData = new FormData();
    formData.append('contractId', data.contractId);
    formData.append('file', data.file);
    return api.post<void>('/cad_objects/import_rastr', formData);
  }

  genFile(data: CadObjectGenFileRequest) {
    return api.post<void>(`/cad_objects/generating_files`, data);
  }

  autocomplete(req: CadObjectsAutocompleteRequest) {
    const { autocompleteAll, ...data } = req;
    return api.post(`/cad_objects/autocomplete`, data, { params: { autocompleteAll } });
  }

  autoRun(data: CadObjectAutoRunRequest) {
    return api.post(`/cad_objects/auto_run`, data);
  }

  autoSign(request: CadObjectAutoSignRequest) {
    return api.post('/cad_objects/auto_sign', request);
  }
  archives(request: CadObjectArchivesRequest) {
    return api.post<CadObjectArchivesResponse>('./cad_objects/archives', request);
  }
}
