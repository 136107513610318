import React, { useCallback } from 'react';
import { Form, message, Modal } from 'antd';
import { CreateContractFormType } from './types';
import { useCreateContactM } from '@/hooks/contracts';
import { CreateContractRequest } from '@/api/Contracts/types';
import { ContractFields } from '@/components/modals/contracts/common/ContractFields';
import { EditContractFormType } from '../EditContractModal/types';
import { DEFAULT_CREATE_CONTRACT_FORM } from './constants';

export type CreateContractModalProps = { visible: false } | ({ visible: true } & VisibleProps);
type VisibleProps = {
  onCancel: () => void;
};

export const CreateContractModal: React.FC<CreateContractModalProps> = React.memo((props) => {
  const { visible } = props;
  if (!visible) {
    return null;
  }

  const { visible: _, ...visibleProps } = props;
  return <VisibleModal {...visibleProps} />;
});
export const VisibleModal: React.FC<VisibleProps> = React.memo((props) => {
  const { onCancel } = props;

  const createContractM = useCreateContactM();
  const [form] = Form.useForm<CreateContractFormType>();

  const onFinish = useCallback(
    async (formData: CreateContractFormType) => {
      try {
        const { isArchiveContract: _isArchiveContract, ...withoutTrash } = formData;
        const req = {
          ...withoutTrash,
          attorney: withoutTrash.attorney?._id,
          order: withoutTrash.order?._id,
          monitoring: withoutTrash.monitoring?._id,
        } as CreateContractRequest;
        await createContractM.mutateAsync(req);
        message.success(`Контракт "${formData.title}" создан`);
        onCancel();
      } catch (e) {
        message.error('При создании контракта произошла ошибка');
      }
    },
    [createContractM, onCancel],
  );

  const onChangeFormHandler = React.useCallback(
    (formPatch: Partial<EditContractFormType>) => {
      form.setFieldsValue(formPatch);
    },
    [form],
  );

  return (
    <Modal
      title={'Создание контракта'}
      width={800}
      visible={true}
      okText={'Создать'}
      onOk={form.submit}
      okButtonProps={{ loading: createContractM.isLoading }}
      cancelText={'Отменить'}
      onCancel={onCancel}
      afterClose={form.resetFields}
      getContainer={false}
    >
      <Form
        initialValues={DEFAULT_CREATE_CONTRACT_FORM}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
      >
        <ContractFields onChangeForm={onChangeFormHandler} />
      </Form>
    </Modal>
  );
});
