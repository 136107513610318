import { SelectCoordinateSystemField } from '@/components/inputs/SelectCoordinateSystemField';
import { TemplateMonitoringField } from '@/components/inputs/TemplateMonitoringField';
import { errorMessages } from '@/utils/errorMessages';
import { Checkbox, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { SelectContractType } from '../../SelectContractType';
import { TabContent } from '../../TabContent';
import { territoryOptions } from '../constants';

const _MainDataTabContent: React.FC = () => {
  return (
    <TabContent>
      <Form.Item
        name={'title'}
        label={'Название'}
        rules={[{ required: true, message: errorMessages.required }]}
      >
        <Input placeholder={'Введите название контракта'} />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'actusCode'}
            label={'Код в Territory'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Select>
              {territoryOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'region'}
            label={'Код региона'}
            rules={[
              {
                required: true,
                transform: (value) => Number.parseInt(value),
                type: 'integer',
                message: 'Укажите валидный код региона',
              },
            ]}
          >
            <Input placeholder={'Введите код региона'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={'type'}
            label={'Тип контракта'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <SelectContractType />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'monitoring'}
            label={'Шаблон мониторинга'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <TemplateMonitoringField />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} align='bottom'>
        <Col span={12}>
          <Form.Item name={'coordinateSystems'} label={'Системы координат'}>
            <SelectCoordinateSystemField />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'minPdCount'}
            label='Минимальное кол-во ПД'
            rules={[
              {
                type: 'integer',
                min: 1,
                message: 'Доступны целые числа > 0',
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={'isArchiveContract'} valuePropName='checked'>
        <Checkbox>Архивный контракт</Checkbox>
      </Form.Item>
    </TabContent>
  );
};
export const MainDataTabContent = React.memo(_MainDataTabContent);
