import { UsersPage } from '@/pages/Administration/UsersPage';
import { CompaniesPage } from '@/pages/CompaniesPage';
import { ContractsPage } from '@/pages/ContractsPage';
import { CoordinateSystemsPage } from '@/pages/Coordinates/CoordinateSystemsPage';
import { TpPage } from '@/pages/Coordinates/TpPage';
import { CadObjectCoordinatesPage } from '@/pages/CoordsPage';
import { ColumnGroupPage } from '@/pages/Dictionary/ColumnGroupPage';
import { ColumnPage } from '@/pages/Dictionary/ColumnPage';
import { CptPage } from '@/pages/Dictionary/CptPage';
import { DisplayModePage } from '@/pages/Dictionary/DisplayModePage';
import { EmployeesPage } from '@/pages/Dictionary/EmployeesPage';
import { OrganizationsPage } from '@/pages/Dictionary/OrganizationsPage';
import MainTablePage from '@/pages/MainTablePage';
import { ManagementOrganization } from '@/pages/ManagementOrganization';
import { routes } from '@/resources/routes';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const _AuthRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.cadObjectDetailCoordinates()}>
        <CadObjectCoordinatesPage />
      </Route>

      <Route path={routes.companies}>
        <CompaniesPage />
      </Route>

      <Route path={routes.contracts} exact>
        <ContractsPage />
      </Route>

      <Route path={routes.contractDetails}>
        <MainTablePage />
      </Route>

      <Route path={routes.coordinates.tp}>
        <TpPage />
      </Route>
      <Route path={routes.coordinates.coordinateSystems}>
        <CoordinateSystemsPage />
      </Route>

      <Route path={routes.dictionary.cpt}>
        <CptPage />
      </Route>
      <Route path={routes.dictionary.employees}>
        <EmployeesPage />
      </Route>
      <Route path={routes.dictionary.organizations}>
        <OrganizationsPage />
      </Route>
      <Route path={routes.dictionary.columnGroups}>
        <ColumnGroupPage />
      </Route>
      <Route path={routes.dictionary.columns}>
        <ColumnPage />
      </Route>
      <Route path={routes.dictionary.displayMods}>
        <DisplayModePage />
      </Route>
      <Route path={routes.managementOrganization}>
        <ManagementOrganization />
      </Route>

      <Route path={routes.administration.users()}>
        <UsersPage />
      </Route>

      <Route path={'/'} exact>
        <Redirect to={routes.companies} />
      </Route>

      <Route>
        <p>Ошибка</p>
      </Route>
    </Switch>
  );
};
export const AuthRouter = React.memo(_AuthRouter);
