import { RecordId } from '@/api/common/types';

export const routes = {
  registration: '/registration',
  login: '/login',
  passwordRestore: '/password-restore',
  passwordRestoreConfirmed: '/password-restore-confirmed',
  companies: '/companies',
  root: '/',
  contracts: '/contracts',
  contractDetails: '/contracts/:id',
  contractDetail: (id: RecordId) => `/contracts/${id}`,
  managementOrganization: '/management-organization',

  dictionary: {
    cpt: '/dictionary/cpt',
    employees: '/dictionary/employees',
    organizations: '/dictionary/organizations',
    columnGroups: '/dictionary/column-groups',
    columns: '/dictionary/columns',
    displayMods: '/dictionary/display-mods',
  },
  coordinates: {
    tp: '/coordinates/tp',
    coordinateSystems: '/coordinates/coordinate-systems',
  },
  cadObjects: '/cadObjects',
  cadObjectDetails: () => `${routes.cadObjects}/:id`,
  cadObjectDetail: (id: RecordId) => `${routes.cadObjects}/${id}`,
  cadObjectDetailCoordinates: () => `${routes.cadObjects}/:id/coordinates`,
  cadObjectDetailCoordinate: (id: RecordId) => `${routes.cadObjects}/${id}/coordinates`,
  testPage: '/testPage',

  administration: {
    root: '/administration',
    users: () => `${routes.administration.root}/users`,
  },
};
