import React from 'react';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { errorMessages } from '@/utils/errorMessages';
import {
  innPhysicalValidator,
  passportNumberValidator,
  passportSeriesValidator,
  snilsValidator,
  telValidator,
} from '@/utils/validators';
import { AddressSearchSelect } from '@uspect-team/ant-ui-kit';
import { SelectOrganization } from '@/components/modals/employee/common/SelectOrganization';
import { SectionHeader } from '@/components/modals/common/SectionHeader';
import { PhoneNumberInput } from '@/components/inputs/special/PhoneNumberInput';
import { PassportSeriesInput } from '@/components/inputs/special/PassportSeriesInput';
import { SnilsInput } from '@/components/inputs/special/SnilsInput';

export const EmployeeFormFields: React.FC = React.memo(() => {
  return (
    <>
      <Row gutter={18}>
        <Col span={8}>
          <Form.Item
            name={'lastName'}
            label={'Фамилия'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Введите фамилию'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'firstName'}
            label={'Имя'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Введите имя'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'patronymic'}
            label={'Отчество'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Введите отчество'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col span={8}>
          <Form.Item
            name={'email'}
            label={'E-mail'}
            rules={[
              { required: true, message: errorMessages.required },
              { type: 'email', message: errorMessages.email },
            ]}
          >
            <Input placeholder={'Введите e-mail'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'phone'}
            label={'Номер телефона'}
            rules={[
              { required: true, message: errorMessages.required },
              { validator: telValidator },
            ]}
          >
            <PhoneNumberInput block />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'address'}
            label={'Адрес по прописке'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <AddressSearchSelect block inputProps={{ autoComplete: 'off' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col span={8}>
          <Form.Item
            name={'snils'}
            label={'СНИЛС'}
            rules={[
              { required: true, message: errorMessages.required },
              { validator: snilsValidator },
            ]}
          >
            <SnilsInput block />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'inn'}
            label={'ИНН'}
            rules={[
              { required: true, message: errorMessages.required },
              { validator: innPhysicalValidator },
            ]}
          >
            <Input placeholder={'1234567890'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={'appointment'}
            label={'Должность'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Введите должность'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col span={8}>
          <Form.Item
            name={'certificate'}
            label={'Сертификат'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'1234-1234-12'} />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name={'organization'}
            label={'Организация'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <SelectOrganization />
          </Form.Item>
        </Col>
      </Row>
      <SectionHeader>Паспорт</SectionHeader>
      <Row gutter={18}>
        <Col span={3}>
          <Form.Item
            name={'passportSeries'}
            label={'Серия'}
            rules={[
              { required: true, message: errorMessages.required },
              { validator: passportSeriesValidator },
            ]}
          >
            <PassportSeriesInput block />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name={'passportNumber'}
            label={'Номер'}
            rules={[
              { required: true, message: errorMessages.required },
              { validator: passportNumberValidator },
            ]}
          >
            <Input placeholder={'123456'} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={'passportDate'}
            label={'Дата выдачи'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'passportOrgan'}
            label={'Кем выдан'}
            rules={[{ required: true, message: errorMessages.required }]}
          >
            <Input placeholder={'Отделением УФМС...'} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});
